import ApiService from '@/api/new/services/api.service'
import { TokenService } from '@/api/new/services/storage.service'
import ApiServiceV2 from '@/api/new/services/v2/api.service'
import i18n from '@/i18n'
import { $cookies } from '@/main'
import { router } from '@/router/index'

const state = {
  account: {},
  accountLangPair: {},
  accountUpdated: false,
  subAccounts: [],
  allSubAccounts: [],
  accountsKeys: [],
  countries: [],
  showListView: true,
  showSupplierListView: false,
  pages: {
    current: 0,
    total: 0
  },
  languages: {
    all: [],
    source: [],
    target: [],
    sourcing: {}
  },
  allTiers: {},
  supportedFileFormats: [],
  supportedMtLanguages: {},
  vatTypes: {},
  contentTypes: []
}

const mutations = {
  setCustomerAccount(state, account) {
    state.account = account
    state.accountLangPair = {}
    if (account.attributes.language_pairs) {
      for (const property in account.attributes.language_pairs) {
        state.accountLangPair[property] =
          account.attributes.language_pairs[property]
      }
    }
  },
  setListView(state, bool) {
    state.showListView = bool
  },
  setSupplierListView(state, bool) {
    state.showSupplierListView = bool
  },
  setAccountsKeys(state, accountsKeys) {
    state.accountsKeys = accountsKeys
  },
  setSubAccounts(state, accounts) {
    state.subAccounts = accounts
  },
  setAllSubAccounts(state, accounts) {
    state.allSubAccounts = accounts
  },
  setPages(state, pages) {
    state.pages.current = pages.current_page
    state.pages.total = pages.last_page
  },
  setAccountUpdate(state, bool) {
    state.accountUpdated = bool
  },
  setCountries(state, countries) {
    state.countries = countries
  },
  setContentTypes(state, contentTypes) {
    state.contentTypes = contentTypes
  },
  setAllLanguages(state, lang) {
    state.languages.all = lang
  },
  setSourceLanguages(state, lang) {
    state.languages.source = lang
  },
  setTargetLanguages(state, lang) {
    state.languages.target = lang
  },
  setTiers(state, allTiers) {
    state.allTiers = allTiers
  },
  setSupportedFileFormats(state, supportedFileFormats) {
    state.supportedFileFormats = supportedFileFormats
  },
  setSupportedMtLanguages(state, languages) {
    state.supportedMtLanguages = languages
  },
  setSourcing(state, sourcing) {
    state.languages.sourcing = sourcing
  },
  setVatTypes(state, types) {
    state.vatTypes = types
  }
}

const actions = {
  async getCustomerAccount({ commit, rootState, rootGetters }) {
    await ApiServiceV2.get(`teams/${rootGetters['workspace/currentAccountId']}`)
      .then(async (res) => {
        const account = res.data.data
        commit('setCustomerAccount', account)
      })
      .catch((err) => {
        if (
          !rootState['modals'].showVerifyMfaModal &&
          !rootState['auth'].isTokenRefreshing
        ) {
          TokenService.removeAll()
          router.push({ name: 'BaseLogin' })
        }
        throw new Error(JSON.stringify(err))
      })
  },
  async getCustomerAccountMessages({ commit, rootGetters }) {
    await ApiService.get(`teams/${rootGetters['workspace/currentAccountId']}`)
      .then(async (res) => {
        const account = res.data.data
        await commit('setCustomerAccount', account)
      })
      .catch((err) => {
        TokenService.removeAll()
        throw new Error(JSON.stringify(err))
      })
  },
  async getAccountKeys({ commit, rootGetters }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/keys`
    )
      .then(async (res) => {
        const keys = res.data.data
        await commit('setAccountsKeys', keys)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateCustomerAccount({ commit, rootGetters }, payload) {
    const data = {
      data: {
        type: 'account',
        id: payload.account.accountId,
        attributes: {
          company_name: payload.account.companyName,
          company_email: payload.account.companyEmail,
          phone: payload.account.phone,
          invoice_language: payload.account.invoiceLanguage,
          billing_address: payload.account.billingAddress,
          billing_postalCode: payload.account.billingPostalCode,
          billing_city: payload.account.billingCity,
          billing_country: payload.account.billingCountry,
          currency: payload.account.currency,
          receipt_email: payload.account.receiptEmail,
          stripe_token: payload.account.stripeAccountToken
        }
      }
    }

    await ApiService.put(
      `teams/${rootGetters['workspace/currentAccountId']}`,
      data
    )
      .then((res) => {
        commit('setCustomerAccount', res.data.data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getCustomerProfile({ dispatch, commit, rootGetters }) {
    await ApiService.get(`teams/${rootGetters['workspace/currentAccountId']}`)
      .then(async (res) => {
        const account = res.data.data
        await dispatch('workspace/getStateCurrentUser', null, { root: true })
        await commit('setCustomerAccount', account)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateCustomerAccountNew({ commit, rootGetters }, payload) {
    const data = {
      data: {
        type: 'account',
        id: payload.id,
        attributes: {
          ...payload.account
        }
      }
    }
    await ApiService.put(
      `teams/${rootGetters['workspace/currentAccountId']}`,
      data
    )
      .then((res) => {
        commit('setCustomerAccount', res.data.data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateCustomerProfile({ rootGetters }, payload) {
    const data = {
      data: {
        type: 'customer',
        id: payload.id,
        attributes: { ...payload.account }
      }
    }
    await ApiService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/customers/${payload.id}`,
      data
    )
      .then()
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getSubAccounts({ commit, rootGetters, dispatch }, { currentPage }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/accounts`,
      {
        params: {
          page: currentPage
        }
      }
    )
      .then(async (res) => {
        const accounts = res.data.data
        const pages = res.data.meta
        commit('setSubAccounts', accounts)
        commit('setPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getStateAllSubAccounts({ dispatch, state }) {
    if (state.allSubAccounts.length === 0) await dispatch('getAllSubAccounts')
  },
  async getAllSubAccounts({ commit, rootGetters }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/all-accounts`
    )
      .then(async (res) => {
        const accounts = res.data.data
        commit('setAllSubAccounts', accounts)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async createSubAccount({ dispatch, commit, rootGetters, state }, payload) {
    const data = {
      data: {
        type: 'sub_account',
        attributes: {
          company_name: payload.company_name,
          email: payload.email,
          team_identifier: payload.team_identifier,
          first_name: payload.first_name,
          last_name: payload.last_name,
          settings: {
            price_list: payload.settings.parent_price_list,
            translation_team: payload.settings.parent_translation_team,
            billing: payload.settings.parent_billing
          }
        }
      }
    }

    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/accounts`,
      data
    )
      .then(async () => {
        await dispatch('getSubAccounts', { currentPage: state.pages.current })
        await dispatch('getCustomerAccount')
        await commit('setAccountUpdate', true)
        dispatch(
          'toast/success',
          { message: i18n.t('shared.toast.success.subaccount_created') },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getSettings({ commit, rootGetters }) {
    const endpoint =
      $cookies.get('userType') !== 'supplier'
        ? `teams/${rootGetters['workspace/currentAccountId']}/settings`
        : 'settings'
    await ApiService.get(endpoint)
      .then(async (res) => {
        const allLang = Object.values(res.data.data[0].attributes.all)
        const source = Object.values(res.data.data[0].attributes.source)
        const target = Object.values(res.data.data[0].attributes.target)
        const countries = res.data.data.find((obj) => obj.type === 'countries')
        const vatTypes = res.data.data.find((obj) => obj.type === 'vat_types')
        const sourcing = res.data.data.find((obj) => obj.type === 'sourcing')
        const allTiers = res.data.data.find(
          (obj) => obj.type === 'subscription_tiers'
        )
        const supportedMtLanguages = res.data.data.find(
          (obj) => obj.type === 'custom_machine_translation'
        )
        const supportedFileFormats = Object.keys(
          res?.data?.data?.find((obj) => obj.type === 'supported_file')
            ?.attributes || {}
        ).filter((f) => f !== 'vtt')
        const contentPillars = res.data.data.find(
          (obj) => obj.type === 'content_pillars'
        )

        commit('setAllLanguages', allLang)
        commit('setSourceLanguages', source)
        commit('setTargetLanguages', target)
        commit('setSourcing', sourcing)
        commit('setVatTypes', vatTypes.attributes)
        commit('setTiers', allTiers)
        commit('setSupportedFileFormats', supportedFileFormats)
        commit('setSupportedMtLanguages', supportedMtLanguages.attributes)
        commit('setCountries', countries.attributes)
        commit('setContentTypes', contentPillars.attributes.content_types)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

const getters = {
  onboardingFinished: (state) => (account) => {
    const onboarding = account.attributes.onboarding
    return Object.keys(onboarding).every((key) => {
      return Object.values(onboarding[key].steps).every((keyName) => {
        return keyName.finished_at !== null
      })
    })
  },
  progressOrder(state) {
    const onboarding = state.account.attributes.onboarding
    return {
      getting_started: onboarding.getting_started,
      team_of_translators: onboarding.team_of_translators,
      first_project: onboarding.first_project,
      automation_flows: onboarding.automation_flows
    }
  },
  isParentAccount(state) {
    return state.account.attributes.boolean_flags.is_parent === true
  },
  conversations(state) {
    if (state.account.attributes)
      return state.account.attributes.alerts.conversations
    return []
  },
  isLocalEnv() {
    return process.env.VUE_APP_ENV === 'local'
  },
  isOnProduction() {
    return process.env.VUE_APP_ENV === 'prod'
  },
  enableOnTesting() {
    return process.env.VUE_APP_ENV !== 'prod'
  },
  hasSandbox() {
    return $cookies.get('env') === 'sandbox'
  },
  freemiumAccount(state) {
    return (
      state?.account?.attributes?.subscription?.attributes?.plan_identifier ===
      'freemium'
    )
  },
  isLaasAccount(state) {
    return (
      state?.account?.attributes?.subscription?.attributes?.plan_identifier ===
      'laas'
    )
  },
  freemiumUsage(state) {
    return (
      state.account.attributes &&
      (state.account.attributes.subscription.attributes.plan_identifier ===
        'freemium' ||
        state.account.attributes.subscription.attributes.plan_identifier ===
          'scaleup' ||
        state.account.attributes.subscription.attributes.plan_identifier ===
          'startup' ||
        state.account.attributes.subscription.attributes.plan_identifier ===
          'enterprise' ||
        state.account.attributes.subscription.attributes.plan_identifier ===
          'usage')
    )
  },
  langCodeFormat: (state) => (code) => {
    let name
    state.languages.all.forEach((lang) => {
      if (lang.code === code) {
        name = i18n.t(`shared.languages.${lang.code}`)
        return
      }
    })
    return name
  },
  sourceCodes(state) {
    return state.languages.source.map((l) => l.code)
  },
  targetCodes(state) {
    return state.languages.target.map((l) => l.code)
  },
  isSalesCustomer(state) {
    return state.account.attributes.subscription.attributes.referral === 'sales'
  },
  isSsoCustomer(state) {
    return state.account.attributes.subscription.attributes.referral === 'sso'
  },
  isShopifyCustomer(state) {
    return (
      state.account.attributes.subscription.attributes.referral === 'shopify'
    )
  },
  isRegularCustomer(state) {
    return !state.account.attributes.subscription.attributes.referral
  },
  enableUpgrade(state) {
    return (
      state?.account?.attributes?.subscription?.attributes?.plan_identifier !==
        'laas' &&
      state?.account?.attributes?.subscription?.attributes?.plan_identifier !==
        'enterprise'
    )
  },
  isFreemiumScaleupStartup(state) {
    return (
      state.account.attributes &&
      (state.account.attributes.subscription.attributes.plan_identifier ===
        'freemium' ||
        state.account.attributes.subscription.attributes.plan_identifier ===
          'scaleup' ||
        state.account.attributes.subscription.attributes.plan_identifier ===
          'startup')
    )
  },
  enableFreeTrial(state, getters, rootState) {
    return (
      state?.account?.attributes?.subscription?.attributes?.plan_identifier ===
        'freemium' &&
      !rootState['payment'].billing.attributes.subscription.attributes
        .first_paid_subscription
    )
  }
}

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
